import { Dispatch } from "redux";
import { License, User } from "../Models/AppState";
import { History } from "history";

// export const apiUrl = 'http://10.3.1.239:5000';
export const apiUrl = "";

export const getAllLicenses = (dispatch: Dispatch, history: History) => {
  const token = localStorage.getItem("token");

  return fetch(`${apiUrl}/api/license/GetAll`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((answer) => {
      if (answer.status === 401) {
        throw Error(answer.status.toString());
      }
      return answer.json();
    })
    .then((json) => dispatch(getAllLicensesSuccessfully(json)))
    .catch((error) => {
      if (Number(error.message) === 401) {
        history.replace("/?logout");
      }
    });
};

export const getAllLicensesSuccessfully = (licenses: Array<License>) => {
  return {
    type: "GET_ALL_LICENSES",
    payload: licenses,
  };
};

export const initUser = (user: User) => {
  return {
    type: "INIT_USER",
    payload: user,
  };
};

export const getAllUsers = (dispatch: Dispatch, history: History) => {
  const token = localStorage.getItem("token");
  fetch(`${apiUrl}/api/user/getAll`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((answer: any) => {
      if (answer.status === 401) {
        throw Error(answer.status);
      }
      return answer.json();
    })
    .then((json) => dispatch(getAllUsersSuccessfully(json)))
    .catch((error) => {
      if (Number(error.message) === 401) {
        history.push("/?logout");
      }
    });
};

export const getAllUsersSuccessfully = (users: Array<User>) => {
  return {
    type: "GET_ALL_USERS",
    payload: users,
  };
};
