export interface AppState {
  mainReducer: MainReducerState;
}

export enum UserLang {
  RUS = "RUS",
  ENG = "ENG",
}
export interface MainReducerState {
  licenses: License[];
  loggedUser: User | null;
  allUsers: User[];
  isInitialized: boolean;
  userLang: UserLang;
}

export interface Action {
  type: string;
  payload: any;
}

export enum LicenseStatus {
  ACTIVATED = "ACTIVATED",
  NOT_ACTIVATED = "NOT_ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
  DEACTIVATED_WITHOUT_KEY = "DEACTIVATED_WITHOUT_KEY",
}

export interface License {
  id: number;
  uuid: string;
  companyName: string;
  isActive: boolean;
  activationsCount: number;
  camAmount?: number;
  detAmount?: number;
  createDate: string;
  expirationDate: string;
  userForeignKey: number;
  user: null;
  activationDate: string;
  status: LicenseStatus;
  partNumber: string;
  detectorType: string;
}

export interface PublicLicense {
  licenseNumber: string;
  camNumber?: number;
  detAmount?: number;
  expirationDate: string;
  createDate: string;
  key: string;
}

export interface User {
  email: string;
  id: number;
  password: string;
}
