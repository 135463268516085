import * as React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  TextField,
  TableFooter,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Select from "react-select";
import { get, uniqBy } from "lodash";
import { SortDirection } from "@material-ui/core/TableCell";
import { ValueType } from "react-select/lib/types";
import TablePagination, {
  LabelDisplayedRowsArgs,
} from "@material-ui/core/TablePagination";
import { TableParamsCtx } from "./EnhancedTableHOC";
import { connect, DispatchProp } from "react-redux";

export const tableFooterLabel = ({ from, to, count }: LabelDisplayedRowsArgs) =>
  `${from}-${to} из ${count}`;

const styles = createStyles({
  table: {
    tableLayout: "fixed",
    "& td": {
      padding: "8px",
    },
  },
  pagination: {
    width: "100%",
  },
});

export interface EnhancedTableRow {
  id: string;
  numeric?: boolean;
  disablePadding?: boolean;
  label: string;
  filter?: string;
  getValue?: (value: any) => string;
  renderCell?: (value: any) => JSX.Element;
}

export interface TableFilter {
  value: string;
  selector: string;
  type: string;
}

interface EnhancedTableProps {
  data: Array<any>;
  rows: EnhancedTableRow[];
  defaultSort: {
    id: string;
    order: SortDirection;
  };
  onRowClick?: (row: any) => void;
}

interface Option {
  label: string;
  value: any;
  data: any;
}

function stableSort<T>(array: T[], cmp: ReturnType<typeof getSorting>) {
  const stabilizedThis = array.map<[T, number]>((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function desc<T>(a: T, b: T, orderBy: string) {
  const orderA = get(a, orderBy, "");
  const orderB = get(b, orderBy, "");
  if (orderB < orderA) {
    return -1;
  }
  if (orderB > orderA) {
    return 1;
  }
  return 0;
}

function getSorting<T>(order: SortDirection, orderBy: string) {
  return order === "desc"
    ? (a: T, b: T) => desc(a, b, orderBy)
    : (a: T, b: T) => -desc(a, b, orderBy);
}

export const EnhancedTableContext = React.createContext([]);

const rowsPerPage = 15;

interface ReduxProps {
  name: string;
  table: {
    page: number;
    filters: TableFilter[];
    order: SortDirection;
    orderBy: string;
  };
}

class TableCmp extends React.Component<
  EnhancedTableProps & DispatchProp & ReduxProps & WithStyles<typeof styles>
> {
  componentDidMount() {
    this.props.dispatch({
      type: "init-table",
      payload: {
        name: this.props.name,
        persistent: true,
        page: 0,
        order: this.props.defaultSort.order
          ? this.props.defaultSort.order
          : "desc",
        orderBy: this.props.defaultSort.id
          ? this.props.defaultSort.id
          : "createdAt",
        filters: [],
      },
    });
  }

  handleRequestFilter = (
    value: string | null,
    selector: string,
    type: string
  ) => {
    this.props.dispatch({
      type: "filter",
      payload: { name: this.props.name, value, selector, type },
    });
  };

  handleRequestSort = (property: string) => {
    const orderBy = property;
    let order: SortDirection = "desc";

    if (
      this.props.table.orderBy === property &&
      this.props.table.order === "desc"
    ) {
      order = "asc";
    }

    this.props.dispatch({
      type: "sort",
      payload: { name: this.props.name, order, orderBy },
    });
  };

  createSortHandler = (property: string) => (event: any) => {
    this.handleRequestSort(property);
  };

  handleSelectChange = (
    option: ValueType<Option>,
    id: string,
    type: string
  ) => {
    if (Array.isArray(option)) {
      // Type of select option is union of array and single - we need to check it
      return;
    }
    if (option === null) {
      // When clear select - option is null
      this.handleRequestFilter(option, id, type);
    }
    if (option && option.value) {
      // When you select a value
      this.handleRequestFilter(option.value, id, type);
    }
  };

  handleTextChange = (value: string, id: string, type: string) => {
    this.handleRequestFilter(value, id, type);
  };

  filterFn = (data: any, filters: TableFilter[]) => {
    if (!filters.length) {
      return data;
    }

    let ret = data;
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === "select") {
        ret = ret.filter(
          (e: any) => get(e, filters[i].selector) === filters[i].value
        );
      } else if (filters[i].type === "text") {
        // need to check if getValue func is used for value rendering
        const row = this.props.rows.find((r) => r.id === filters[i].selector);

        if (row && row.getValue) {
          ret = ret.filter((e: any) => {
            // @ts-ignore
            const stringVal = row.getValue(e);
            return (
              stringVal
                .toUpperCase()
                .indexOf(filters[i].value.toUpperCase()) !== -1
            );
          });
        } else {
          ret = ret.filter(
            (e: any) =>
              String(get(e, filters[i].selector))
                .toUpperCase()
                .indexOf(filters[i].value.toUpperCase()) !== -1
          );
        }
      }
    }
    return ret;
  };

  changePage = (page: number) => {
    this.props.dispatch({
      type: "set_page",
      payload: { name: this.props.name, page: page },
    });
  };

  getFilterValue = (row: EnhancedTableRow, filters: TableFilter[]) => {
    const findFilter = filters.find((f) => f.selector === row.id);
    return findFilter ? findFilter.value : "";
  };

  render() {
    const { classes, data, rows, table, onRowClick } = this.props;
    const { order, orderBy } = table;
    const sorted = stableSort(data, getSorting(order, orderBy));
    const filtered = this.filterFn(sorted, table.filters);
    return (
      <>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {rows.map((row, i) => {
                return (
                  <TableCell
                    key={row.id}
                    numeric={row.numeric}
                    padding={row.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === row.id ? order : false}
                    style={{width: i === 0 ? 340: undefined}}
                  >
                    <Tooltip
                      title="Sorting"
                      placement={row.numeric ? "bottom-end" : "bottom-start"}
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order as any}
                        onClick={this.createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {rows.map((row) => {
                const uniqPos = uniqBy(data, row.id);
                return (
                  <TableCell
                    key={row.id}
                    numeric={row.numeric}
                    padding={row.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    {row.filter && row.filter === "select" && (
                      <Select
                        name={row.id}
                        options={uniqPos.map((e) => ({
                          label: get(e, row.id),
                          value: get(e, row.id),
                          data: e,
                        }))}
                        onChange={(option) =>
                          this.handleSelectChange(option, row.id, "select")
                        }
                        isClearable
                      />
                    )}
                    {row.filter && row.filter === "text" && (
                      <TextField
                        type="search"
                        onChange={(event) =>
                          this.handleTextChange(
                            event.target.value,
                            row.id,
                            "text"
                          )
                        }
                        value={this.getFilterValue(row, table.filters)}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {this.props.children ? (
            <EnhancedTableContext.Provider value={filtered}>
              {this.props.children}
            </EnhancedTableContext.Provider>
          ) : (
            <>
              <TableBody>
                {filtered
                  .slice(
                    table.page * rowsPerPage,
                    table.page * rowsPerPage + rowsPerPage
                  )
                  .map((n: any) => (
                    <TableRow
                      hover
                      key={n.id}
                      onClick={onRowClick ? () => onRowClick(n) : () => false}
                    >
                      {rows.map((r) => {
                        if (r.renderCell) {
                          return r.renderCell(n);
                        } else {
                          return (
                            <TableCell key={r.id}>
                              {r.getValue ? r.getValue(n) : get(n, r.id)}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={rows.length}
                rowsPerPageOptions={[]}
                count={filtered.length}
                rowsPerPage={rowsPerPage}
                page={this.props.table.page}
                onChangePage={(event, page) => this.changePage(page)}
                labelDisplayedRows={tableFooterLabel}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const defaultTableState = { page: 0, filters: [] };
  return {
    table: state.table[ownProps.name]
      ? state.table[ownProps.name]
      : defaultTableState,
  };
};

const EnhancedTableConnected = connect(mapStateToProps)(
  withStyles(styles)(TableCmp)
);

export default class EnhancedTable extends React.Component<EnhancedTableProps> {
  static contextType = TableParamsCtx;
  render() {
    return <EnhancedTableConnected {...this.props} name={this.context.name} />;
  }
}
